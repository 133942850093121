import React from 'react';
import TitleSection from '../TitleSection';
import './SplitSection.css';

interface Props {
  id: string,
  backgroundStyle: string, // Possible values: white, grey, green
  title?: string,
  left?: any,
  right?: any,
  top?: any,
  bottom?: any,
}

export default function SplitSection({
  id, backgroundStyle, title, left, right, top, bottom,
}: Props) {
  return (
    <section id={id} className={`SplitSection ${backgroundStyle}`}>

      { title
          && <TitleSection title={title} />}

      { left
          && (
          <div className="SplitSection-content-horizontal">
            <div className="SplitSection-left">
              {left}
            </div>
            <div className="SplitSection-right">
              {right}
            </div>
          </div>
          )}

      { top
          && (
          <div className="SplitSection-content-vertical">
            <div className="SplitSection-top">
              {top}
            </div>
            <div className="SplitSection-bottom">
              {bottom}
            </div>
          </div>
          )}

    </section>
  );
}
SplitSection.defaultProps = {
  title: null,
  left: null,
  right: null,
  top: null,
  bottom: null,
};
