import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface BackToSiteProps {
  className?: string,
}

export default function BackToSite({ className }: BackToSiteProps) {
  const { t } = useTranslation();

  return (
    <div className={className} style={{ marginTop: '50px' }}>
      <Link to="/" className="actionButton">
        &#8592;
        {' '}
        {t('utils.back-to-site')}
      </Link>
    </div>
  );
}

BackToSite.defaultProps = {
  className: '',
};
