import React from 'react';
import { useTranslation } from 'react-i18next';
import './ContactForm.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export default function ContactForm() {
  const { t } = useTranslation();

  const formRef = React.createRef<HTMLFormElement>();

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = formRef;

    emailjs.sendForm('default_service', 'contact_form', '#form', process.env.REACT_APP_EMAILJS_USERID)
      .then(() => {
        toast(t('contact.success'));
        if (form.current) {
          form.current.reset();
        }
      }, (error) => {
        console.log(error);
        toast(t('contact.error'));
      });
  };

  return (
    <div>
      <form id="form" className="ContactForm" ref={formRef} onSubmit={sendEmail}>
        <label htmlFor="name">{t('contact.name')}</label>
        <input id="name" type="text" name="contact_name" className="ContactForm-input" required />
        <label htmlFor="email">{t('contact.email')}</label>
        <input id="email" type="email" name="contact_email" className="ContactForm-input" required />
        <label htmlFor="message">{t('contact.message')}</label>
        <textarea id="message" name="contact_message" className="ContactForm-textarea" required />
        <button type="submit" className="actionButton ContactForm-button">{t('contact.submit')}</button>
      </form>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        transition={Slide}
        hideProgressBar
      />
    </div>
  );
}
