import React from 'react';
import './TeamMember.css';

interface Props {
  picture: any,
  pictureWebP: any,
  name: string,
  children: any,
}

export default function TeamMember({
  pictureWebP, picture, name, children,
}: Props) {
  return (
    <div className="TeamMember">
      <picture>
        <source type="image/webp" srcSet={pictureWebP} />
        <source type="image/jpeg" srcSet={picture} />
        <img src={picture} alt={name} className="circle" />
      </picture>
      <h2>{name}</h2>
      <div>
        {children}
      </div>
    </div>
  );
}
