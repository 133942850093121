import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import TitleSection from '../../components/TitleSection';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Projects.css'; // Needs to be after slick styles

import VVMT from './Slides/VVMT';
import Telemaque from './Slides/Telemaque';
import Nesspay from './Slides/Nesspay';
import CollectifMentorat from './Slides/CollectifMentorat';

export default function Projects() {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="projects" className="Projects">
      <TitleSection title={t('projects.title')} />
      <div className="container">
        <Slider {...settings}>
          <div><VVMT /></div>
          <div><Telemaque /></div>
          <div><Nesspay /></div>
          <div><CollectifMentorat /></div>
        </Slider>
      </div>
    </section>
  );
}
