import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/TitleSection';
import Blog from '../../Blog/Blog';

export default function News() {
  const { t } = useTranslation();

  return (
    <section id="news">
      <TitleSection title={t('news.title')} />
      <div style={{ maxWidth: '1400px', margin: 'auto' }}>
        <Blog />
      </div>
    </section>
  );
}
