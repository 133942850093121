import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import StickyHeader from '../StickyHeader';
import BackToSite from '../components/BackToSite';
import useGetPostsService, { GetPostsResponse, WrappedResponse } from './GitlabService';
import './Blog.css';

export default function BlogPost() {
  const { iid } = useParams<{ iid: string, }>();

  const { t } = useTranslation();

  const postsResponse: WrappedResponse<GetPostsResponse> = useGetPostsService(iid);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr', options);
  };

  return (
    <>
      <StickyHeader menu={false} />
      <div className="blog">
        <div className="article">
          <BackToSite />

          { postsResponse.status === 'loading' && (
          <p style={{ textAlign: 'center' }}>{t('blog.loading')}</p>
          )}

          { postsResponse.status !== 'loading'
        && (postsResponse.payload === undefined || postsResponse.payload.length === 0) && (
          <p style={{ textAlign: 'center' }}>{t('blog.not-found')}</p>
          )}

          { postsResponse.status === 'success'
        && postsResponse.payload !== undefined
        && postsResponse.payload[0] && (
          <>
            <h1>{postsResponse.payload[0].title}</h1>
            <div className="post-warning">
              Afin de réduire l&apos;impact de cet article, nous avons volontairement limité
              l&apos;usage des images et désactivé la fonctionnalité des commentaires.
              Si vous voulez commenter/discuter, nous vous invitons à nous interpeler
              publiquement via LinkedIn ou en privé via le formulaire de contact de
              notre site.

            </div>
            <div className="post-content"><ReactMarkdown>{ postsResponse.payload[0].description }</ReactMarkdown></div>
            <div className="post-info-text" style={{ textAlign: 'right' }}>
              Auteur :
              {' '}
              { postsResponse.payload[0].assignee?.name || postsResponse.payload[0].author.name }
            </div>
            <div className="post-info-text" style={{ textAlign: 'right' }}>
              Dernière modification :
              {' '}
              { formatDate(postsResponse.payload[0].updated_at) }
            </div>
          </>
          )}

          <BackToSite />
        </div>
      </div>
    </>
  );
}
