import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

export interface WrappedResponse<T> {
  status: 'loading' | 'success' | 'error',
  error?: Error,
  payload?: T,
}

interface GitlabAuthor {
  name: string,
  avatar_url: string,
}

export interface GitlabIssue {
  iid: string,
  id: string,
  title: string,
  description: string,
  updated_at: string,
  labels: string[],
  author: GitlabAuthor,
  assignee: GitlabAuthor,
  due_date: string,
}

export type GetPostsResponse = GitlabIssue[]

const useGetPostsService = (iid?: string): WrappedResponse<GetPostsResponse> => {
  const [postsResponse, setPostsResponse] = useState<WrappedResponse<GetPostsResponse>>({
    status: 'loading',
  });

  const getPosts = (): Promise<GetPostsResponse> => axios.get<GetPostsResponse>(
    'https://gitlab.com/api/v4/projects/23515315/issues',
    {
      params: {
        iids: (iid) ? [iid] : null,
        order_by: 'updated_at',
        sort: 'desc',
      },
    },
  )
    .then((response: AxiosResponse<GetPostsResponse>) => response.data);

  useEffect(() => {
    if (iid) {
      setPostsResponse({ status: 'loading' });
    }
    getPosts()
      .then((data) => setPostsResponse({ status: 'success', payload: data }))
      .catch((error) => setPostsResponse({ status: 'error', error }));
    // eslint-disable-next-line
    }, [])

  return postsResponse;
};

export default useGetPostsService;
