import React from 'react';
import { useTranslation } from 'react-i18next';
import collectifMentoratWebP from '../../../assets/img/collectif_mentorat.webp';
import collectifMentoratPng from '../../../assets/img/collectif_mentorat.png';
import collectifMentoratWebsiteJpg from '../../../assets/img/collectif_mentorat_website.jpg';
import collectifMentoratWebsiteWebP from '../../../assets/img/collectif_mentorat_website.webp';
import '../Projects.css';

export default function CollectifMentorat() {
  const { t } = useTranslation();

  return (
    <div className="slide">
      <div style={{ maxWidth: '500px' }}>
        <a style={{ color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.1jeune1mentor.fr/">
          <picture>
            <source type="image/webp" srcSet={collectifMentoratWebP} />
            <source type="image/png" srcSet={collectifMentoratPng} />
            <img src={collectifMentoratPng} style={{ display: 'block', marginBottom: '30px' }} alt="Collectif Mentorat" className="project-logo" />
          </picture>
        </a>
        <p>{t('projects.collectif_mentorat.part1')}</p>
        <p>{t('projects.collectif_mentorat.part2')}</p>
        <p>
          {t('projects.collectif_mentorat.signature')}
          {t('utils.colon')}
          <span style={{ fontFamily: 'Handlee' }}>
            {' '}
            &ldquo;
            {t('projects.collectif_mentorat.testimonial')}
            &ldquo;
          </span>
        </p>
      </div>
      <div style={{ maxWidth: '600px', textAlign: 'center' }}>
        <picture>
          <source type="image/webp" srcSet={collectifMentoratWebsiteWebP} />
          <source type="image/jpeg" srcSet={collectifMentoratWebsiteJpg} />
          <img src={collectifMentoratWebsiteJpg} alt="" />
        </picture>
      </div>
    </div>

  );
}
