import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Blog.css';
import useGetPostsService, { GetPostsResponse, WrappedResponse } from './GitlabService';

export default function Blog() {
  const { t } = useTranslation();

  const LIMIT_NB_POST = 3;

  const postsResponse: WrappedResponse<GetPostsResponse> = useGetPostsService();

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr', options);
  };

  const truncate = (text: string) => {
    const EXCERPT_LENGTH = 120;
    return text.length > EXCERPT_LENGTH ? `${text.substring(0, EXCERPT_LENGTH - 3)}...` : text;
  };

  return (
    <div className="card-list">

      { postsResponse.status === 'loading' && (
        <p style={{ textAlign: 'center' }}>{t('blog.loading')}</p>
      )}

      { postsResponse.status !== 'loading'
      && (postsResponse.payload === undefined || postsResponse.payload.length === 0) && (
        <p style={{ textAlign: 'center' }}>{t('blog.empty-list')}</p>
      )}

      { postsResponse.status === 'success'
      && postsResponse.payload !== undefined
      && postsResponse.payload.length > 0
      && postsResponse.payload.slice(0, LIMIT_NB_POST).map((post) => (
        <div className="card" key={post.id}>
          <p className="post-info-text" style={{ textTransform: 'uppercase' }}>{ formatDate(post.updated_at) }</p>
          <h2>{post.title}</h2>
          <div className="card-description"><ReactMarkdown>{ truncate(post.description) }</ReactMarkdown></div>
          <Link to={`/blog/${post.iid}`} key={post.id} className="actionButton">{t('blog.link-article')}</Link>
        </div>
      ))}

    </div>
  );
}
