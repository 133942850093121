import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ImageDevelopment from '../../assets/img/services_development.svg';
import ImageMethodology from '../../assets/img/services_methodology.svg';
import ImageInfogerance from '../../assets/img/services_maintenance.svg';
import ImagePrototype from '../../assets/img/services_prototype.svg';
import ImageAudit from '../../assets/img/services_audit.svg';

import SplitSection from '../../components/SplitSection';
import './Services.css';

export default function Services() {
  const { t } = useTranslation();

  return (
    <SplitSection
      id="services"
      title={t('services.title')}
      backgroundStyle="white"
      top={(
        <div className="Services-text">
          <p>{t('services.part1')}</p>
          <p>{t('services.part2')}</p>
          <p>{t('services.part3')}</p>
        </div>
    )}
      bottom={(
        <div className="Services-images">
          <div>
            <img src={ImageMethodology} alt={t('services.consulting.description')} />
            <h2>{t('services.consulting.head')}</h2>
            <p>{t('services.consulting.detail')}</p>
          </div>
          <div>
            <img src={ImageDevelopment} alt={t('services.development.description')} />
            <h2>{t('services.development.head')}</h2>
            <p>{t('services.development.detail')}</p>
            <p>
              <Link to="/blog/1" key="1" className="actionButton">
                {t('services.link-blog')}
              </Link>
            </p>
          </div>
          <div>
            <img src={ImageInfogerance} alt={t('services.maintenance.description')} />
            <h2>{t('services.maintenance.head')}</h2>
            <p>{t('services.maintenance.detail')}</p>
            <p>
              <Link to="/blog/2" key="2" className="actionButton">
                {t('services.link-blog')}
              </Link>
            </p>
          </div>
          <div>
            <img src={ImagePrototype} alt={t('services.prototyping.description')} />
            <h2>{t('services.prototyping.head')}</h2>
            <p>{t('services.prototyping.detail')}</p>
          </div>
          <div>
            <img src={ImageAudit} alt={t('services.audit.description')} />
            <h2>{t('services.audit.head')}</h2>
            <p>{t('services.audit.detail')}</p>
            <p>
              <Link to="/blog/5" key="5" className="actionButton">
                {t('services.link-blog')}
              </Link>
            </p>
          </div>
        </div>
      )}
    />
  );
}
