import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/TitleSection';
import ContactForm from './ContactForm';
import './Contact.css';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <section id="contact" className="Contact">
      <TitleSection title={t('contact.title')} />
      <ContactForm />
    </section>
  );
}
