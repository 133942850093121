import React from 'react';
import { useTranslation } from 'react-i18next';
import TelemaqueWebP from '../../../assets/img/telemaque.webp';
import TelemaquePng from '../../../assets/img/telemaque.png';
import '../Projects.css';

export default function Telemaque() {
  const { t } = useTranslation();

  return (
    <div className="slide">
      <div style={{ maxWidth: '600px', margin: '40px 0' }}>
        <a style={{ color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.institut-telemaque.org/">
          <picture>
            <source type="image/webp" srcSet={TelemaqueWebP} />
            <source type="image/jpeg" srcSet={TelemaquePng} />
            <img src={TelemaquePng} style={{ display: 'block' }} alt="Logo Télémaque" className="project-logo" />
          </picture>
        </a>
        <p>{t('projects.telemaque.part1')}</p>
        <p>{t('projects.telemaque.part2')}</p>
        <p>
          Marion
          {' '}
          {t('utils.from')}
          {' '}
          Télémaque
          {t('utils.colon')}
          <span style={{ fontFamily: 'Handlee' }}>
            {' '}
            &ldquo;
            {t('projects.telemaque.testimonial')}
            &ldquo;
          </span>
        </p>
      </div>
    </div>

  );
}
