import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageOrigin from '../../assets/img/origin_meeting.svg';
import SplitSection from '../../components/SplitSection';

export default function Origin() {
  const { t } = useTranslation();

  return (
    <SplitSection
      id="origin"
      title={t('origin.title')}
      backgroundStyle="white"
      left={(
        <div>
          <h2 className="handwriting">{t('origin.subtitle')}</h2>
          <p>{t('origin.part1')}</p>
          <p>{t('origin.part2')}</p>
        </div>
    )}
      right={
        <img src={ImageOrigin} className="mobileHide" alt={t('origin.description')} />
    }
    />
  );
}
