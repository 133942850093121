import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLanguage, MdPlayArrow } from 'react-icons/md';

import './LanguageSwitcher.css';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const setLang = (lang: string) => setCurrentLanguage(lang);

  const [isDisplayBlock, setIsDisplayBlock] = useState('none');

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [i18n, currentLanguage]);

  const displayDropdown = () => {
    if (isDisplayBlock === 'none') {
      setIsDisplayBlock('block');
    } else {
      setIsDisplayBlock('none');
    }
  };

  return (
    <div className="dropdown">
      <button className="language-button" onFocus={displayDropdown} type="button">
        <MdLanguage size="25px" />
      </button>
      <div style={{ display: isDisplayBlock }} className="dropdown-content">
        <button onClick={() => setLang('fr')} type="button">
          { currentLanguage === 'fr' ? <MdPlayArrow size="20px" /> : ''}
          <span style={currentLanguage === 'fr' ? { marginLeft: 0 } : { marginLeft: '20px' }}>Français</span>
        </button>

        <button onClick={() => setLang('en')} onBlur={displayDropdown} type="button">
          { currentLanguage === 'en' ? <MdPlayArrow size="20px" /> : ''}
          <span style={currentLanguage === 'en' ? { marginLeft: 0 } : { marginLeft: '20px' }}>English</span>
        </button>
      </div>
    </div>
  );
}
