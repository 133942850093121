import React from 'react';
import { useTranslation } from 'react-i18next';
import NesspayWebP from '../../../assets/img/Nesspay.webp';
import NesspayPng from '../../../assets/img/Nesspay.png';
import NesspayMobileWebP from '../../../assets/img/nesspay-mobile.webp';
import NesspayMobilePng from '../../../assets/img/nesspay-mobile.png';
import '../Projects.css';

export default function Nesspay() {
  const { t } = useTranslation();

  return (
    <div className="slide">
      <div style={{ maxWidth: '600px' }}>
        <a style={{ color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://nesspay.co/">
          <picture>
            <source type="image/webp" srcSet={NesspayWebP} />
            <source type="image/png" srcSet={NesspayPng} />
            <img src={NesspayPng} style={{ display: 'block' }} alt="Logo Nesspay" className="project-logo" />
          </picture>
        </a>
        <p>{t('projects.nesspay.part1')}</p>
        <p>{t('projects.nesspay.part2')}</p>
        <p>
          Gary
          {' '}
          {t('utils.from')}
          {' '}
          Nesspay
          {t('utils.colon')}
          <span style={{ fontFamily: 'Handlee' }}>
            {' '}
            &ldquo;
            {t('projects.nesspay.testimonial')}
            &ldquo;
            {' '}
          </span>
        </p>
      </div>
      <div style={{ maxWidth: '600px', textAlign: 'center' }}>
        <picture>
          <source type="image/webp" srcSet={NesspayMobileWebP} />
          <source type="image/png" srcSet={NesspayMobilePng} />
          <img src={NesspayMobilePng} style={{ maxHeight: '600px' }} alt="Application NessPay sur mobile" />
        </picture>
      </div>
    </div>

  );
}
