import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/TitleSection';
import ImageQuality from '../../assets/img/values_quality.svg';
import ImageTransparency from '../../assets/img/values_transparency.svg';
import ImageCommitment from '../../assets/img/values_commitment.svg';
import './Values.css';

export default function Values() {
  const { t } = useTranslation();

  return (
    <section id="values" className="grey">
      <TitleSection title={t('values.title')} />
      <div className="content">
        <img src={ImageQuality} className="mobileHide" alt={t('values.quality.description')} />
        <div>
          <h2>{t('values.quality.title')}</h2>
          <p>
            {' '}
            {t('values.quality.part1')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.quality.part2')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.quality.part3')}
            {' '}
          </p>
        </div>
      </div>
      <div className="content">
        <img src={ImageTransparency} className="mobileHide" alt={t('values.transparency.description')} />
        <div>
          <h2>{t('values.transparency.title')}</h2>
          <p>
            {' '}
            {t('values.transparency.part1')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.transparency.part2')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.transparency.part3')}
            {' '}
          </p>
        </div>
      </div>
      <div className="content">
        <img src={ImageCommitment} className="mobileHide" alt={t('values.commitment.description')} />
        <div>
          <h2>{t('values.commitment.title')}</h2>
          <p>
            {' '}
            {t('values.commitment.part1')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.commitment.part2')}
            {' '}
          </p>
          <p>
            {' '}
            {t('values.commitment.part3')}
            {' '}
          </p>
        </div>
      </div>
    </section>
  );
}
