import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageTech4GoodJPG from '../../assets/img/partners_tech4good.jpg';
import ImageTech4GoodWebP from '../../assets/img/partners_tech4good.webp';
import ImageLatitudesPNG from '../../assets/img/partners_latitudes.png';
import ImageLatitudesWebP from '../../assets/img/partners_latitudes.webp';
import ImageGreenITJPG from '../../assets/img/partners_collectif_greenit.jpg';
import ImageGreenITWebP from '../../assets/img/partners_collectif_greenit.webp';
import ImageSocialDeclikJPG from '../../assets/img/partners_socialdeclik.jpg';
import ImageSocialDeclikWebP from '../../assets/img/partners_socialdeclik.webp';
import TitleSection from '../../components/TitleSection';
import './Partners.css';

export default function Valeurs() {
  const { t } = useTranslation();

  return (
    <section id="partners" className="SplitSection grey">
      <TitleSection title={t('partners.title')} />

      <div className="content">
        <picture>
          <source type="image/webp" srcSet={ImageTech4GoodWebP} />
          <source type="image/jpeg" srcSet={ImageTech4GoodJPG} />
          <img
            src={ImageTech4GoodJPG}
            alt="Tech 4 Good"
            className="img-square"
          />
        </picture>
        <picture>
          <source type="image/webp" srcSet={ImageLatitudesWebP} />
          <source type="image/png" srcSet={ImageLatitudesPNG} />
          <img
            src={ImageLatitudesPNG}
            alt="Latitudes"
            className="img-rectangle"
          />
        </picture>
        <picture>
          <source type="image/webp" srcSet={ImageGreenITWebP} />
          <source type="image/jpeg" srcSet={ImageGreenITJPG} />
          <img src={ImageGreenITJPG} alt="Green IT" className="img-square" />
        </picture>
        <picture>
          <source type="image/webp" srcSet={ImageSocialDeclikWebP} />
          <source type="image/jpeg" srcSet={ImageSocialDeclikJPG} />
          <img
            src={ImageSocialDeclikJPG}
            alt="Social Declik"
            className="img-square"
          />
        </picture>
      </div>
    </section>
  );
}
