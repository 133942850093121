import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/TitleSection';
import TeamMember from './TeamMember';

// images jpg
import PictureBoris from '../../assets/img/team_boris.jpg';
import PictureJulien from '../../assets/img/team_julien.jpg';
import PictureAlain from '../../assets/img/team_awl.jpg';
import PictureSandrine from '../../assets/img/team_sandrine.jpg';
import PictureAnais from '../../assets/img/team_anais.jpg';
import PictureGauthier from '../../assets/img/team_gauthier.jpg';
import PictureJeanBernard from '../../assets/img/team_jbp.jpg';
import PictureXavier from '../../assets/img/team_xavier.jpg';
import PictureTristan from '../../assets/img/team_tristan.jpg';
import PictureDavid from '../../assets/img/team_david.jpg';
import PictureDune from '../../assets/img/team_dune.jpg';

// images webp
import PictureBorisWebP from '../../assets/img/team_boris.webp';
import PictureJulienWebP from '../../assets/img/team_julien.webp';
import PictureAlainWebP from '../../assets/img/team_awl.webp';
import PictureSandrineWebP from '../../assets/img/team_sandrine.webp';
import PictureAnaisWebP from '../../assets/img/team_anais.webp';
import PictureGauthierWebP from '../../assets/img/team_gauthier.webp';
import PictureJeanBernardWebP from '../../assets/img/team_jbp.webp';
import PictureXavierWebP from '../../assets/img/team_xavier.webp';
import PictureTristanWebP from '../../assets/img/team_tristan.webp';
import PictureDavidWebP from '../../assets/img/team_david.webp';
import PictureDuneWebP from '../../assets/img/team_dune.webp';

import './Team.css';

const teamMembers = [
  {
    picture: PictureBoris,
    pictureWebP: PictureBorisWebP,
    name: 'Boris Oudet',
    translation: 'team.boris',
  },
  {
    picture: PictureAlain,
    pictureWebP: PictureAlainWebP,
    name: 'Alain-Williams Lecieux',
    translation: 'team.awl',
  },
  {
    picture: PictureSandrine,
    pictureWebP: PictureSandrineWebP,
    name: 'Sandrine Ricardo',
    translation: 'team.sandrine',
  },
  {
    picture: PictureJulien,
    pictureWebP: PictureJulienWebP,
    name: 'Julien Parmentier',
    translation: 'team.julien',
  },
  {
    picture: PictureAnais,
    pictureWebP: PictureAnaisWebP,
    name: 'Anaïs Altun',
    translation: 'team.anais',
  },
  {
    picture: PictureGauthier,
    pictureWebP: PictureGauthierWebP,
    name: 'Gauthier Jenczak',
    translation: 'team.gauthier',
  },
  {
    picture: PictureXavier,
    pictureWebP: PictureXavierWebP,
    name: 'Xavier Desoindre',
    translation: 'team.xavier',
  },
  {
    picture: PictureJeanBernard,
    pictureWebP: PictureJeanBernardWebP,
    name: 'Jean-Bernard Péridy',
    translation: 'team.jeanBernard',
  },
  {
    picture: PictureTristan,
    pictureWebP: PictureTristanWebP,
    name: 'Tristan Gueguen',
    translation: 'team.tristan',
  },
  {
    picture: PictureDavid,
    pictureWebP: PictureDavidWebP,
    name: 'David Tebele',
    translation: 'team.david',
  },
  {
    picture: PictureDune,
    pictureWebP: PictureDuneWebP,
    name: 'Dune Jullian',
    translation: 'team.dune',
  },
];

export default function Team() {
  const { t } = useTranslation();

  return (
    <section id="team" className="Team">
      <TitleSection title={t('team.title')} />
      <div className="TeamMembers">
        {teamMembers.map((member) => (
          <TeamMember
            key={`member__${member.translation}`}
            picture={member.picture}
            pictureWebP={member.pictureWebP}
            name={member.name}
          >
            <p dangerouslySetInnerHTML={{ __html: t(member.translation) }} />
          </TeamMember>
        ))}
      </div>
    </section>
  );
}
