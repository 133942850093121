import React from 'react';
import { useTranslation } from 'react-i18next';
import VVMTWebP from '../../../assets/img/vvmt.webp';
import VVMTPng from '../../../assets/img/vvmt.png';
import VVMTMobileWebP from '../../../assets/img/vvmt-mobile.webp';
import VVMTMobilePng from '../../../assets/img/vvmt-mobile.png';
import '../Projects.css';

export default function VVMT() {
  const { t } = useTranslation();

  return (
    <div className="slide">
      <div style={{ maxWidth: '600px' }}>
        <a style={{ color: '#000000' }} target="_blank" rel="noopener noreferrer" href="https://www.viensvoirmontaf.fr/">
          <picture>
            <source type="image/webp" srcSet={VVMTWebP} />
            <source type="image/jpeg" srcSet={VVMTPng} />
            <img src={VVMTPng} style={{ display: 'block' }} alt="Logo VVMT" className="project-logo" />
          </picture>
        </a>
        <p>{t('projects.vvmt.part1')}</p>
        <p>{t('projects.vvmt.part2')}</p>
        <p>
          David
          {' '}
          {t('utils.from')}
          {' '}
          ViensVoirMonTaf
          {t('utils.colon')}
          <span style={{ fontFamily: 'Handlee' }}>
            {' '}
            &ldquo;
            {t('projects.vvmt.testimonial')}
            &ldquo;
            {' '}
          </span>
        </p>
      </div>
      <div style={{ maxWidth: '600px', textAlign: 'center' }}>
        <picture>
          <source type="image/webp" srcSet={VVMTMobileWebP} />
          <source type="image/jpeg" srcSet={VVMTMobilePng} />
          <img src={VVMTMobilePng} style={{ maxHeight: '600px' }} alt="Application VVMT sur mobile" />
        </picture>
      </div>
    </div>

  );
}
