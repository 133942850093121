import React from 'react';
import TitleSection from '../components/TitleSection';
import BackToSite from '../components/BackToSite';
import StickyHeader from '../StickyHeader';
import './LegalTerms.css';

export default function LegalTerms() {
  return (
    <>
      <StickyHeader menu />
      <div id="legalterms">
        <TitleSection title="Mentions Légales" />
        <div className="legalterms-content">
          <h2>Informations juridiques</h2>
          <p>
            Le site pathtech.fr est réalisé par SCOP arl Pathtech, société au
            capital social variable de 10 000€.
            <br />
            N° SIRET : 89015202800019
            <br />
            Code APE : 6202A
            <br />
            N° RCS : 890 152 028 R.C.S. Paris
            <br />
            Siège social : SCOP arl Pathtech, 11 rue de Lourmel, 75015 Paris.
            <br />
            Contact :
            {' '}
            <a
              href="mailto:contact@pathtech.fr"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact@pathtech.fr
            </a>
            <br />
            Directeur de publication : Boris Oudet
          </p>
          <h2>Hébergeur</h2>
          <p>
            OVH (Kimsufi)
            <br />
            SAS au capital de 10 069 020 €
            <br />
            RCS Lille Métropole 424 761 419 00045
            <br />
            Code APE 2620Z
            <br />
            N° TVA : FR 22 424 761 419
            <br />
            Siège social : 2 rue Kellermann - 59100 Roubaix - France
          </p>
          <h2>Protection de la vie privée et confidentialité</h2>
          <p>
            Le site est exempté de déclaration à la CNIL dans la mesure où il ne
            collecte aucune données de ses utilisateurs.
          </p>
          <h2>Accès au site</h2>
          <p>
            Le site est accessible par tout endroit, 7j/7 24h/24; sauf en cas de
            force majeure, interruption programmée ou maintenance.
          </p>
          <p>
            En cas de modification, interruption ou suspension des services, le
            site pathtech.fr ne pourrait en être retenu responsable.
          </p>
          <h2>Réserve juridique</h2>
          <p>
            L’éditeur s’efforce de vous offrir dans ce site une information
            fiable et actualisée. Mais, malgré tout le soin qu’il apporte, des
            erreurs ou des omissions peuvent survenir. Il s’efforce de corriger
            les erreurs qui lui seront signalées.
          </p>
          <p>L’information contenue dans ce site :</p>
          <ul>
            <li>
              Est destinée à vous apporter des indications générales sur les
              sujets traités;
            </li>
            <li>
              Ne constitue pas un conseil personnalisé, de quelque nature qu’il
              soit (organisationnel, marketing, juridique, fiscal, etc.), lequel
              conseil personnalisé suppose l’étude et l’analyse de cas
              particuliers par un professionnel de la spécialité;
            </li>
            <li>
              Ne doit pas être utilisée sans les conseils préalables des experts
              concernés.
            </li>
          </ul>
          <p>
            L’éditeur ne peut en aucun cas être tenu responsable de
            l’utilisation et de l’interprétation de l’information contenue dans
            ce site.
          </p>
          <h2>Absence de garantie</h2>
          <p>
            L’éditeur ne garantit pas que ce site soit exempt de défaut,
            d’erreur ou d’omission et qu’il corresponde exactement à l’usage
            auquel l’utilisateur le destine. Il ne peut en aucun cas être tenu
            responsable des éventuels défauts, erreurs ou omissions existant
            dans ce site, et des éventuelles pertes financières ou autres
            dommages pouvant en résulter.
          </p>
          <h2>Liens hypertextes</h2>
          <p>
            L’éditeur est totalement libre de référencer, de ne pas référencer
            et de supprimer à tout moment le référencement de tel ou tel site.
          </p>
          <p>
            En référençant des sites, il ne cherche pas à être exhaustifs; par
            conséquent, ses listes de liens, dans quelque rubrique qu’elles
            soient, ne prétendent en aucun cas à l’exhaustivité.
          </p>
          <p>
            Ce site comporte des liens hypertextes vers des sites dont l’éditeur
            a constaté l’existence en s’y connectant au moment où ils ont été
            répertoriés et sur lesquels il a effectué une vérification rapide.
            Ces liens hypertextes ont été classifiés selon ses rubriques, en
            fonction de leur contenu, et, quand elles existent, des indications
            fournies par les éditeurs.
          </p>
          <p>
            L’éditeur ne contrôle pas les site qu’il référence, dont la
            responsabilité relève uniquement de leurs éditeurs. Il ne garantit
            absolument pas le contenu de ces sites, et notamment le caractère
            véridique des informations qu’ils comportent. En outre, il ne
            contrôle pas la présence éventuelle de virus informatiques dans les
            sites référencés ; par conséquent, il ne peut en aucun cas garantir
            que ces sites en soient exempts. Il appartient à chaque internaute
            d’exercer son libre arbitre en décidant d’accéder à tel ou tel site.
            Les risques liés à l’utilisation de ces sites incombent donc
            pleinement à l’utilisateur. Le réseau internet étant constamment en
            évolution, il est possible qu’un site que l’éditeur a référencé ait
            été modifié dans son contenu, qu’il ait changé d’adresse ou même
            disparu. L’éditeur s’assure régulièrement que les liens sont
            toujours actifs, mais il n’effectue aucun autre contrôle.
          </p>
          <p>
            Tout site souhaitant interdire ou limiter son référencement
            hypertexte peut demander à l’éditeur de supprimer tout ou partie des
            liens qu’il a créer vers lui en écrivant par mail à
            {' '}
            <a
              href="mailto:contact@pathtech.fr"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact@pathtech.fr
            </a>
            .
          </p>
        </div>
      </div>
      <BackToSite className="legalterms-content" />
    </>
  );
}
