/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import LogoWebP from '../assets/img/pathtech_burger_blanc.webp';
import LogoPng from '../assets/img/pathtech_burger_blanc.png';
import LanguageSwitcher from './LanguageSwitcher';
import './StickyHeader.css';

interface StickyHeaderProps {
  menu: Boolean,
}

export default function StickyHeader({ menu }: StickyHeaderProps) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const switchOpen = () => {
    setOpen(!open);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <nav className="StickyHeader">
      <NavHashLink to="/#home" smooth>
        <picture>
          <source type="image/webp" srcSet={LogoWebP} />
          <source type="image/png" srcSet={LogoPng} />
          <img src={LogoPng} alt="Pathtech" className="StickyHeader-logo" />
        </picture>
      </NavHashLink>
      { menu
        && (
        <div className="StickyHeader-menu">
          <div className={open ? 'StickyHeader-burger wgt868only StickyHeader-cross' : 'StickyHeader-burger wgt868only'} onClick={switchOpen}>
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </div>
          <ul className={open ? 'open' : 'close'}>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#origin"
                smooth
                onClick={onMenuClose}
              >
                {t('header.origin')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#values"
                smooth
                onClick={onMenuClose}
              >
                {t('header.values')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#services"
                smooth
                onClick={onMenuClose}
              >
                {t('header.services')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#projects"
                smooth
                onClick={onMenuClose}
              >
                {t('header.projects')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#team"
                smooth
                onClick={onMenuClose}
              >
                {t('header.team')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#news"
                smooth
                onClick={onMenuClose}
              >
                {t('header.news')}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="StickyHeader-link-item"
                activeClassName="active"
                to="/#contact"
                smooth
                onClick={onMenuClose}
              >
                {t('header.contact')}
              </NavHashLink>
            </li>
            <li className="header-separator" />
            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
        )}
    </nav>
  );
}
