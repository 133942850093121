import React from 'react';
import Home from './Home';
import Origin from './Origin';
import Values from './Values';
import Services from './Services';
import Projects from './Projects';
import Team from './Team';
import Partners from './Partners';
import News from './News';
import Contact from './Contact';
import StickyHeader from '../StickyHeader';

export default function Main() {
  return (
    <>
      <StickyHeader menu />
      <Home />
      <Origin />
      <Values />
      <Services />
      <Projects />
      <Team />
      <News />
      <Partners />
      <Contact />
    </>
  );
}
