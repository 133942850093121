import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './Main';
import BlogPost from './Blog/BlogPost';
import LegalTerms from './LegalTerms';
import Footer from './Footer';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/legal" component={LegalTerms} />
          <Route path="/blog/:iid" component={BlogPost} />
          <Route path="/" component={Main} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
