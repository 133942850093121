import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import LogoJpg from '../assets/img/logo_burger_blanc_footer.png';
import LogoWebP from '../assets/img/logo_burger_blanc_footer.webp';
import './Footer.css';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="Footer">
      <picture>
        <source type="image/webp" srcSet={LogoWebP} />
        <source type="image/jpeg" srcSet={LogoJpg} />
        <img src={LogoJpg} alt="Logo Pathtech" className="Footer-logo mobileHide" />
      </picture>
      <p>
        {t('footer.copyright')}
&nbsp;-&nbsp;
        <HashLink to="/legal#top">{t('footer.terms')}</HashLink>
      </p>
    </div>
  );
}
