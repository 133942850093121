import React from 'react';
import './TitleSection.css';

interface Props {
  title: string,
}

function TitleSection({ title }: Props) {
  return (
    <div className="TitleSection">
      <div style={{ width: '600px', margin: '0 40px' }}>
        <h1 className="TitleSection-label">{title}</h1>
        <div className="TitleSection-line" />
      </div>
      <div style={{ width: '600px' }} />
    </div>
  );
}

export default TitleSection;
