import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import LogoWebP from '../../assets/img/logo_burger_blanc.webp';
import LogoPng from '../../assets/img/logo_burger_blanc.png';
import ImageHome from '../../assets/img/home_www.svg';
import SplitSection from '../../components/SplitSection';
import './Home.css';

export default function Home() {
  const { t } = useTranslation();

  return (
    <SplitSection
      id="home"
      backgroundStyle="green"
      left={(
        <div>
          <picture>
            <source type="image/webp" srcSet={LogoWebP} />
            <source type="image/png" srcSet={LogoPng} />
            <img src={LogoPng} alt="Logo Pathtech" className="Home-logo" />
          </picture>

          <h1 className="Home-title">{t('home.title')}</h1>
          <h2 className="handwriting">{t('home.subtitle')}</h2>
          <HashLink to="/#origin" className="actionButton reverseButtonColor" smooth>{t('home.button')}</HashLink>
        </div>
    )}
      right={
        <img src={ImageHome} className="mobileHide" alt={t('home.description')} />
    }
    />
  );
}
